import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery,Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"


import HeaderMinimal from "../../../components/header/HeaderMinimal"
import MainMenu from "../../../components/header/Menu"
import Footer from "../../../components/footer/Footer"

import Sidebar from "../../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../../components/major-pieces/pageblock/PageBlock"
import CovidPlan from "../../../../static/pdf/school/School-Covid-Plan-Update-11-9-20.pdf"

import "../../../styles/global.css"
import "../../../styles/global_media.css"
import "../../../styles/menu.css"
import "../../../styles/school.css"
import "../../../styles/header.css"


function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/school/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
	        
			}
	`



	let data = useStaticQuery(query)
	console.log(data)

	let images = data.allFile

	
	const sidebarImage = images.edges.find(element => element.node.name === 'sidebar').node


	const campusImage = images.edges.find(element => element.node.name === 'corner').node

	const column1 = images.edges.find(element => element.node.name === '1').node
	const column2 = images.edges.find(element => element.node.name === '2').node
	const column3 = images.edges.find(element => element.node.name === '3').node


	const intensives = images.edges.find(element => element.node.name === 'intensives').node


	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content school-colors school">
				<div>
					<div className="subheader">
					   <h1 className="name"> 
					   		Saint John's Orthodox Christian School
				       </h1>
					</div>
					
				</div>

				<div  className = "fellowship">
					<BackgroundImage
		           		className="fellowship-main-image"
		           		fluid={campusImage.childImageSharp.fluid}
		               	alt="" >
			               	<div className="text-overlay">
								<h2>Teaching Fellowship/Internship in Alaska</h2>
								<div>Saint John Orthodox Christian School - 2022-2023</div>
							</div>
		            </BackgroundImage>

		            <div className="desc">
		            		<b>Saint John Orthodox Christian School</b> in Eagle River, Alaska is offering a 10-month, residential fellowship/internship for a full-time, primary teacher. Saint John’s School has 100 students in grades <b>Preschool through 8th grade</b>, who come from a variety of religious backgrounds. The School is part of the campus of Saint John Cathedral and sits at the base of the Chugach Mountains, 10 miles outside of Anchorage. We are looking for an energetic teacher ready to offer direct classroom instruction, who is also ready to have an Alaskan adventure. The School will provide on-campus housing, a reliable, 4-wheel drive car, travel expenses to Alaska, and a stipend of $1,200 per month. Applicants can read our Mission Statement and learn more about our School on the <a href="/" target="_blank">Cathedral website</a> or on the <a href="https://sjocs.org" target="_blank">School </a>website.

		            </div>
		            <div className="intern-vs-fellow">
						We are offering this as a “Fellowship” to someone who has teaching experience and can be a lead teacher, or as an “Internship” to someone who has a college degree or teaching experience and will work alongside another teacher. This is an exciting new venture for our School and we hope it might be an exciting adventure for you.
		            </div>
		            <div className="qualifications">
			            <h4>Qualifications</h4>
			            <ul >
			            	<li className="qualification">A love of working with children and an ability to interact with parents and co-workers in a confident, and respectful manner</li>
			            	<li className="qualification">College-level training with a bachelor’s degree, or significant experience as a successful teacher.</li>
			            	<li className="qualification">Good classroom management skills</li>
			            	<li className="qualification">An Orthodox Christian in good standing with the Church, or a serious Christian willing to learn and be supportive of the Orthodox Christian faith and way of life</li>
			            	
			            </ul>
  					</div>
  					<div className="steps">
	  					<h4>Steps to apply</h4>
	  					<ol>
	  						<li className="step">Fill-out online application which will include contact information, a few short questions, a written paragraph, and professional and personal references. 
							</li>
	  						<li className="step">Submit a resume to <a href="mailto:sjocs1@gmail.com">sjocs1@gmail.com</a> with academic history and work experience.</li>
	  						<li className="step">Phone interview (30 minutes) where you can introduce yourself and ask questions about our School.</li>
	  						<li className="step">Second interview via zoom with members of School Administration where you can share more of your teaching experiences.
							</li>
	  						<li className="step">Final Review which will also include a criminal background check.</li>
	  					</ol>
	  				</div>
	  				<div className="deadline">Deadline for online application is May 7th</div>
	  						               	<Link className="download-btn school-link apply-btn" to='apply'>Apply</Link>

				</div>
					

				
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 
